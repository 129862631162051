import * as React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

export const CallOrdersList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField label="Телефон" source="phone" />
      <TextField label="Статус" source="state" />
      <TextField label="Дата та час" source="orderDateTime" />
      <EditButton label="Обробити" />
    </Datagrid>
  </List>
);
