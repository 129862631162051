import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput
} from "react-admin";

export const CompanyDataEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput disabled source="localId" />
      <TextInput label="Телефон" source="phone" />
      <TextInput style={{width:'50%'}} label="Email" source="email" />
      <TextInput style={{width:'100%'}} label="Адреса" source="address" />
      <TextInput style={{width:'100%'}} label="Назва компанії" source="companyName" />
    </SimpleForm>
  </Edit>
);
