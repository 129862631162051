import * as React from "react";
import { Edit, SimpleForm, TextInput, SelectInput } from "react-admin";
import MyFileField from "../fieds/MyFileField";

export const OrdersEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput disabled source="id" />
      <SelectInput
        label="Форма скла"
        source="shape"
        choices={[
          { id: "Стандартна", name: "Стандартна" },
          { id: "Не стандартна", name: "Не стандартна" },
        ]}
      ></SelectInput>
      <TextInput label="Висота" disabled source="height" />
      <TextInput label="Ширина" disabled source="width" />
      <TextInput label="Кількість отворів" source="holesAmount" />
      <TextInput label="Діаметр отворів" source="diameter" />
      <TextInput label="Кількість товару" source="glassAmount" />
      <TextInput label="Ім'я" source="name" />
      <TextInput label="Телефон" source="phone" />
      <TextInput label="Email" source="email" />
      <SelectInput
        label="Доставка"
        source="shippingMethod"
        choices={[
          { id: "Нова Пошта", name: "Нова Пошта" },
          { id: "Самовивіз", name: "Самовивіз" },
        ]}
      ></SelectInput>
      <TextInput label="Місто" source="city" />
      <TextInput label="Відділення №" source="shippingDepartmentNumber" />
      <SelectInput
        label="Оплата"
        source="paymentMethod"
        choices={[
          { id: "Готівка", name: "Готівка" },
          { id: "На картку", name: "На картку" },
          { id: "Накладений платіж", name: "Накладений платіж" },
        ]}
      ></SelectInput>
      <MyFileField label="Файл" source="file" />
      <TextInput label="Дата та час" disabled source="orderDateTime" />
      <SelectInput
        label="Статус"
        source="state"
        choices={[
          { id: "Оброблено✅", name: "Оброблено✅" },
          { id: "Не оброблено❌", name: "Не оброблено❌" },
        ]}
      ></SelectInput>
    </SimpleForm>
  </Edit>
);
