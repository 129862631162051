import * as React from "react";
import { Edit, SimpleForm, TextInput, SelectInput } from "react-admin";
export const PriceEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput label="Ціна" source="price" />
      <SelectInput
        label="Валюта"
        source="currency"
        choices={[
          { id: "USD", name: "USD" },
          { id: "EUR", name: "EUR" },
          { id: "PLN", name: "PLN" },
          { id: "UAH", name: "UAH" },
        ]}
      ></SelectInput>
    </SimpleForm>
  </Edit>
);
