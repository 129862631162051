import * as React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import Currencies from "./currencies";
import "./dashboardStyles/index";
import DashboardHello from "./dashboardHello";
import MyCurrencies from "./myCurrencies";
export default () => (
  <React.Fragment>
    <DashboardHello />
    <Currencies />
    <MyCurrencies/>
  </React.Fragment>
);
