import * as React from "react";
import { Edit, SimpleForm, TextInput, SelectInput } from "react-admin";

export const BackCallsEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="Ім'я" disabled source="name" />
      <TextInput label="Телефон" disabled source="phone" />
      <TextInput label="Дата та час" disabled source="dateTime" />
      <SelectInput
        label="Статус"
        source="state"
        choices={[
          { id: "Оброблено✅", name: "Оброблено" },
          { id: "Не оброблено❌", name: "Не оброблено" },
        ]}
      ></SelectInput>
    </SimpleForm>
  </Edit>
);
