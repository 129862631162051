import * as React from "react";
import { List, Datagrid, TextField, EditButton } from 'react-admin';


export const CompanyDataList = () => (
    <List >
        <Datagrid rowClick="edit">
            <TextField label="Мова" source="localId"/>
            <TextField label="Телефон" source="phone" />
            <TextField label="Email" source="email" />
            <TextField label="Адреса" source="address" />
            <TextField label="Назва компанії" source="companyName" />
            <EditButton label="Змінити"/>
        </Datagrid>
    </List>
);

