import * as React from "react";
import { Admin, Resource } from "react-admin";
import { CallOrdersList } from "./components/lists/CallOrders";
import { OrdersList } from "./components/lists/Orders";
import { PriceList } from "./components/lists/Price";
import { BackCallsList } from "./components/lists/BackCalls";
import { CompanyDataList } from "./components/lists/CompanyData";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import CalculateIcon from '@mui/icons-material/Calculate';
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Dashboard from "./components/dashboard";
import authProvider from "./components/authProvider";
import dataProvider from "./components/dataProvider";
import { PriceEdit } from "./components/edits/PriceEdit";
import { OrdersEdit } from "./components/edits/OrdersEdit";
import { CallOrdersEdit } from "./components/edits/CallOrdersEdit";
import { CompanyDataEdit } from "./components/edits/CompanyDataEdit";

import {BackCallsEdit} from "./components/edits/BackCallsEdit";
const App = () => (
  <Admin
    authProvider={authProvider}
    dashboard={Dashboard}
    dataProvider={dataProvider}
  >
    <Resource name="orders" options={{ label: 'Замовлення' }} list={OrdersList} edit={OrdersEdit} icon={ShoppingBagRoundedIcon} />
    <Resource name="backcalls" options={{ label: 'Замовлення дзвінка' }} list={BackCallsList} edit={BackCallsEdit} icon={LocalPhoneRoundedIcon} />
    <Resource name="callOrders" options={{ label: 'Розрахувати ціну' }} list={CallOrdersList} edit={CallOrdersEdit} icon={CalculateIcon} />
    <Resource name="prices" options={{ label: 'Ціна' }} list={PriceList} edit={PriceEdit}  icon={LocalOfferIcon} />
    <Resource name="companydata" options={{ label: 'Дані копанії' }} list={CompanyDataList} edit={CompanyDataEdit} icon={AccountBoxIcon} />
  </Admin>
);
export default App;
