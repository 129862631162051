import React from "react";
import authProvider from "../authProvider";
import { useGetIdentity } from 'react-admin';
function DashboardHello() {
    const { identity, isLoading: identityLoading } = useGetIdentity();
  function TimeText() {
    const Digital = new Date();
    const hours = Digital.getHours();
    if (hours >= 5 && hours <= 22) {
      return <h2>Доброго дня,{name}</h2>;
    }
    return <h2>Доброї ночі,{name}</h2>;
  }
  const name = 'Yuriy Kuhar'
  return (
    <div className="dashboardHello-wrapper">
      <div>
        <TimeText />
        
      </div>
    </div>
  );
}

export default DashboardHello;