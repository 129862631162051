import * as React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

export const PriceList = () => (
  <List>
    <Datagrid>
      <TextField label="Ціна" source="price" />
      <TextField label="Валюта" source="currency" />
      <EditButton label="Змінити" />
    </Datagrid>
  </List>
);
