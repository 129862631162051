import * as React from "react";
import { List, Datagrid, TextField, SimpleList, EditButton } from "react-admin";
import { useMediaQuery } from "@mui/material";

export const OrdersList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) => `${record.phone}`}
          tertiaryText={(record) =>
            new Date(record.orderDateTime).toLocaleDateString()
          }
        />
      ) : (
        <Datagrid>
          <TextField source="id" />
          <TextField label="Висота" source="height" />
          <TextField label="Ширина" source="width" />
          <TextField label="Кількість" source="glassAmount" />
          <TextField label="Ім'я" source="name" />
          <TextField label="Телефон" source="phone" />
          <TextField label="Статус" source="state"/>
          <TextField label="Дата та час" source="orderDateTime" />
          <EditButton label="Обробити"/>
        </Datagrid>
      )}
    </List>
  );
};
