import * as React from "react";
import { useRecordContext } from "react-admin";
const MyFileField = ({ source }) => {
  const record = useRecordContext();
  console.log(record[source]);
  return (
    <object>
      <embed
        title="file"
        src={require(`../../images/${record[source]}`)}
        height="300"
      />
    </object>
  );
};
export default MyFileField;
